/*!
	Lato font.
*/
/* Lato (hairline, regular) */
@font-face {
  font-family: Lato;
  font-weight: 100;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-hairline/lato-hairline.woff2") format("woff2"), url("../fonts/lato-hairline/lato-hairline.woff") format("woff");
}
/* Lato (hairline, italic) */
@font-face {
  font-family: Lato;
  font-weight: 100;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-hairline-italic/lato-hairline-italic.woff2") format("woff2"), url("../fonts/lato-hairline-italic/lato-hairline-italic.woff") format("woff");
}
/* Lato (thin, regular) */
@font-face {
  font-family: Lato;
  font-weight: 200;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-thin/lato-thin.woff2") format("woff2"), url("../fonts/lato-thin/lato-thin.woff") format("woff");
}
/* Lato (thin, italic) */
@font-face {
  font-family: Lato;
  font-weight: 200;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-thin-italic/lato-thin-italic.woff2") format("woff2"), url("../fonts/lato-thin-italic/lato-thin-italic.woff") format("woff");
}
/* Lato (light, regular) */
@font-face {
  font-family: Lato;
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-light/lato-light.woff2") format("woff2"), url("../fonts/lato-light/lato-light.woff") format("woff");
}
/* Lato (light, italic) */
@font-face {
  font-family: Lato;
  font-weight: 300;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-light-italic/lato-light-italic.woff2") format("woff2"), url("../fonts/lato-light-italic/lato-light-italic.woff") format("woff");
}
/* Lato (normal, regular) */
@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-normal/lato-normal.woff2") format("woff2"), url("../fonts/lato-normal/lato-normal.woff") format("woff");
}
/* Lato (normal, italic) */
@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-normal-italic/lato-normal-italic.woff2") format("woff2"), url("../fonts/lato-normal-italic/lato-normal-italic.woff") format("woff");
}
/* Lato (medium, regular) */
@font-face {
  font-family: "Lato Medium";
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-medium/lato-medium.woff2") format("woff2"), url("../fonts/lato-medium/lato-medium.woff") format("woff");
}
/* Lato (medium, italic) */
@font-face {
  font-family: "Lato Medium";
  font-weight: 400;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-medium-italic/lato-medium-italic.woff2") format("woff2"), url("../fonts/lato-medium-italic/lato-medium-italic.woff") format("woff");
}
/* Lato (semibold, regular) */
@font-face {
  font-family: Lato;
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-semibold/lato-semibold.woff2") format("woff2"), url("../fonts/lato-semibold/lato-semibold.woff") format("woff");
}
/* Lato (semibold, italic) */
@font-face {
  font-family: Lato;
  font-weight: 500;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-semibold-italic/lato-semibold-italic.woff2") format("woff2"), url("../fonts/lato-semibold-italic/lato-semibold-italic.woff") format("woff");
}
/* Lato (bold, regular) */
@font-face {
  font-family: Lato;
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-bold/lato-bold.woff2") format("woff2"), url("../fonts/lato-bold/lato-bold.woff") format("woff");
}
/* Lato (bold, italic) */
@font-face {
  font-family: Lato;
  font-weight: 600;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-bold-italic/lato-bold-italic.woff2") format("woff2"), url("../fonts/lato-bold-italic/lato-bold-italic.woff") format("woff");
}
/* Lato (heavy, regular) */
@font-face {
  font-family: Lato;
  font-weight: 800;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-heavy/lato-heavy.woff2") format("woff2"), url("../fonts/lato-heavy/lato-heavy.woff") format("woff");
}
/* Lato (heavy, italic) */
@font-face {
  font-family: Lato;
  font-weight: 800;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-heavy-italic/lato-heavy-italic.woff2") format("woff2"), url("../fonts/lato-heavy-italic/lato-heavy-italic.woff") format("woff");
}
/* Lato (black, regular) */
@font-face {
  font-family: Lato;
  font-weight: 900;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-black/lato-black.woff2") format("woff2"), url("../fonts/lato-black/lato-black.woff") format("woff");
}
/* Lato (black, italic) */
@font-face {
  font-family: Lato;
  font-weight: 900;
  font-style: italic;
  text-rendering: optimizeLegibility;
  src: url("../fonts/lato-black-italic/lato-black-italic.woff2") format("woff2"), url("../fonts/lato-black-italic/lato-black-italic.woff") format("woff");
}
